:root {
  --aarp-red: #ec1300;
  --aarp-blue: #0086ec;
  --aarp-text-dark: #37383d;
  --aarp-text-grey: #676767;
  --aarp-text-disabled: #7c7c7c;
  --aarp-font: 'Lato', sans-serif;
  --aarp-outline: #c8c6c6;
}

:not(#pdf-document *, .rmwc-icon) { /* Don't apply to elements under pdf root or icons */
  font-family: var(--aarp-font);
  scrollbar-color: var(--aarp-red);
}

*::-webkit-scrollbar-thumb {
  background-color: var(--aarp-red) !important;
}

.forced-active {
  color: #fff;
  background-color: var(--aarp-red);
  border-radius: 28px;
}

.mdc-list-item--selected, .mdc-list-item--activated {
  color: var(--aarp-red) !important;
}

:not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--selected::before, :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--selected::after {
  background-color: rgb(236, 19, 0, 1) !important;
}